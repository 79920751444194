// src/components/Navbar.js
import React, { useState, useContext, useEffect, useCallback, memo, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../firebase-config';
import { signOut } from 'firebase/auth'; 
import { UserContext } from '../contexts/UserContext';
import { CartContext } from '../contexts/CartContext';
import logo from '../assets/logo-lunadeoro.png'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faShoppingCart, faUserPlus, faHome, faGem, faInfoCircle, faContactBook } from '@fortawesome/free-solid-svg-icons';
import { ROUTES } from '../constants/routes';
import {
  Nav,
  StyledLogo,
  NavLinksWrapper,
  NavLink,
  UserIconWrapper,
  Dropdown,
  CartIcon,
  StyledFontAwesomeIcon,
  StyledText
} from './Navbar.styles';

// Memoized components
const Logo = memo(({ src, alt }) => (
  <Link to={ROUTES.HOME}>
    <StyledLogo src={src} alt={alt} />
  </Link>
));

const NavLinkWithIcon = memo(({ to, icon, children }) => (
  <NavLink to={to}>
    <StyledFontAwesomeIcon icon={icon} />
    {children}
  </NavLink>
));

const Navbar = () => {
  const { user, setUser } = useContext(UserContext);
  const { cartItems } = useContext(CartContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [jewelryDropdownVisible, setJewelryDropdownVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const toggleJewelryDropdown = (event) => {
    event.preventDefault();
    setJewelryDropdownVisible(prev => !prev);
  };

  useEffect(() => {
    setDropdownVisible(false);
    setJewelryDropdownVisible(false);
    
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setJewelryDropdownVisible(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await signOut(auth);
      setUser(null);
      navigate(ROUTES.HOME);
    } catch (error) {
      console.error('Error signing out:', error);
      // Consider showing an error message to the user
      // setError('Failed to log out. Please try again.');
    }
  }, [setUser, navigate]);

  const toggleDropdown = useCallback(() => setDropdownVisible(prev => !prev), []);
  //const toggleJewelryDropdown = useCallback(() => setJewelryDropdownVisible(prev => !prev), []);
  

  return (
    <Nav>
      <Logo src={logo} alt="Luna D'Oro Logo" />
      <NavLinksWrapper>
        <NavLinkWithIcon to={ROUTES.HOME} icon={faHome}>Home</NavLinkWithIcon>
        <div style={{ position: 'relative' }}>
          <button onClick={toggleJewelryDropdown} style={{ background: 'none', border: 'none', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <StyledFontAwesomeIcon icon={faGem} style={{ marginRight: '8px', fontSize: '16px' }} />
            <StyledText>Jewelry</StyledText>
          </button>
          
          {jewelryDropdownVisible && (
            <Dropdown ref={dropdownRef}>
              <Link to={`${ROUTES.PRODUCTS}?prefix=NEC`} key="NEC">Necklaces</Link>
              <Link to={`${ROUTES.PRODUCTS}?prefix=RIN`} key="RIN">Rings</Link>
              <Link to={`${ROUTES.PRODUCTS}?prefix=EAR`} key="EAR">Earrings</Link>
              <Link to={`${ROUTES.PRODUCTS}?prefix=BRA`} key="BRA">Bracelets</Link>
            </Dropdown>
          )}
        </div>
        <NavLinkWithIcon to={ROUTES.ABOUT} icon={faInfoCircle}>About Us</NavLinkWithIcon>
        <NavLinkWithIcon to={ROUTES.CONTACT} icon={faContactBook}>Contact Us</NavLinkWithIcon>

        <CartIcon>
          <NavLink to={ROUTES.CART} aria-label="Shopping Cart">
            <FontAwesomeIcon icon={faShoppingCart} />
            Cart
            {cartItems.length > 0 && <span aria-label={`${cartItems.length} items in cart`}>{cartItems.length}</span>}
          </NavLink>
        </CartIcon>

        {!user ? (
          <>
            <NavLinkWithIcon to={ROUTES.LOGIN} icon={faUser}>Login</NavLinkWithIcon>
            <NavLinkWithIcon to={ROUTES.SIGNUP} icon={faUserPlus}>Sign Up</NavLinkWithIcon>
          </>
        ) : (
          <UserIconWrapper>
            <div onClick={toggleDropdown}>
              <FontAwesomeIcon icon={faUser} color="#fff" />
            </div>
            
            {dropdownVisible && (
              <Dropdown>
                <Link to={ROUTES.PROFILE} key="profile">Profile</Link>
                <Link to={ROUTES.ORDER_HISTORY} key="order-history">Order History</Link>
                <Link to={ROUTES.HOME} key="logout" onClick={handleLogout}>Logout</Link>
              </Dropdown>
            )}
          </UserIconWrapper>
        )}
      </NavLinksWrapper>
    </Nav>
  );
};

export default Navbar;