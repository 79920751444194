// src/components/Layout.js
import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children, openModal }) => (
  <>
    <Navbar openModal={openModal} />
    <main>{children}</main>
    <Footer />
  </>
);

export default Layout;