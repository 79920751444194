// src/routes.js
import React, { lazy } from 'react';

const HomePage = lazy(() => import('./pages/Home'));
const LoginForm = lazy(() => import('./components/LoginForm'));
const SignUpForm = lazy(() => import('./components/SignUpForm'));
const ProfileScreen = lazy(() => import('./pages/ProfileScreen'));
const PaymentForm = lazy(() => import('./components/PaymentForm'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const About = lazy(() => import('./pages/About'));
const CartPage = lazy(() => import('./pages/Cart'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const ProductsPage = lazy(() => import('./pages/ProductsPage'));
const SuccessPage = lazy(() => import('./pages/SuccessPage'));
const CancelPage = lazy(() => import('./pages/CancelPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));

const routes = [
  { path: '/', element: <HomePage /> },
  { path: '/login', element: <LoginForm /> },
  { path: '/signup', element: <SignUpForm /> },
  { path: '/about', element: <About /> },
  { path: '/cart', element: <CartPage /> },
  { path: '/contact', element: <ContactPage /> },
  { path: '/profile', element: <ProfileScreen />, protected: true },
  { path: '/payment', element: <PaymentForm />, protected: true },
  { path: '/product', element: <ProductsPage /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/success', element: <SuccessPage /> },
  { path: '/cancel', element: <CancelPage /> },
  { path: '*', element: <ErrorPage /> },
];

export default routes;