// src/components/ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext'; // Import UserContext

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(UserContext); // Access user from context

  if (!user) {
    // If no user is logged in, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // Render the protected component if the user is authenticated
  return children;
};

export default ProtectedRoute;
