// src/components/Navbar.styles.js
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px; /* Set the desired height */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 3%;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  box-sizing: border-box;
`;

export const StyledLogo = styled.img`
  height: 100px;
  width: auto;
  object-fit: contain;
`;

export const NavLinksWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const NavLink = styled(Link)`
  font-family: 'Cinzel', serif;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  
  &:hover {
    color: ${({ theme }) => theme.colors.accent};
    transition: color 0.3s ease;
  }

  display: flex;
  align-items: center;
`;

export const UserIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

export const Dropdown = styled.div`
  position: absolute;
  right: 0;
  top: 50px;
  background-color: ${({ theme }) => theme.colors.background};
  min-width: 160px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;

  a {
    color: ${({ theme }) => theme.colors.text};
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: 'Cinzel', serif;
    font-size: 1rem;
  }

  a:hover {
    background-color: ${({ theme }) => theme.colors.accent};
  }
`;

export const CartIcon = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.2rem;
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 6px;
  color: #fff;
`;

export const JewelryDropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const JewelryDropdown = styled(Dropdown)`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
`;

export const StyledText = styled.span`
  font-family: 'Cinzel', serif;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text};
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
    transition: color 0.3s ease;
  }
`;