// src/constants/routes.js
export const ROUTES = {
    HOME: '/',
    PRODUCTS: '/product',
    ABOUT: '/about',
    CONTACT: '/contact',
    CART: '/cart',
    LOGIN: '/login',
    SIGNUP: '/signup',
    PROFILE: '/profile',
    ORDER_HISTORY: '/order-history',
  };