// src/styles/theme.js
export const theme = {
  colors: {
    primary: '#000000', // Solid black background for the navbar
    secondary: '#8B5E3C',
    accent: '#C5A880', // Gold accent color matching the logo
    background: '#1E1E1E',
    text: '#F5F5F5', // Light gold for navbar text
    button: '#C5A880', // Button background color matching the logo
  },
  fonts: {
    primary: '"Playfair Display", serif', // Elegant serif font for headings
    secondary: '"Roboto", sans-serif', // Default font for body text
  },
};
