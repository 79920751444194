// src/App.js
import React, { Suspense, lazy } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { CartProvider } from './contexts/CartContext';
import { theme } from './styles/theme';
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorBoundary from './components/ErrorBoundary';
import useModal from './hooks/useModal';
import routes from './routes';

// Lazy load components
const LoginModal = lazy(() => import('./components/LoginModal'));

function App() {
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <ErrorBoundary>
      <UserProvider>
        <CartProvider>
          <ThemeProvider theme={theme}>
            <Router>
              <Layout openModal={openModal}>
                <Suspense fallback={<LoadingSpinner />}>
                  <Routes>
                    {routes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={
                          route.protected ? (
                            <ProtectedRoute>{route.element}</ProtectedRoute>
                          ) : (
                            route.element
                          )
                        }
                      />
                    ))}
                  </Routes>
                  {isModalOpen && <LoginModal closeModal={closeModal} />}
                </Suspense>
              </Layout>
            </Router>
          </ThemeProvider>
        </CartProvider>
      </UserProvider>
    </ErrorBoundary>
  );
}

export default App;