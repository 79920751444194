// src/components/LoadingSpinner.js
import React from 'react';
import styled from 'styled-components';

const Spinner = styled.div`
  // Add your spinner styles here
`;

const LoadingSpinner = () => <Spinner>Loading...</Spinner>;

export default LoadingSpinner;