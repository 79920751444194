// src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC1EyeLagzc53PhV3OHljmmCZJJORBq79Q",
    authDomain: "lunadeoro-da985.firebaseapp.com",
    projectId: "lunadeoro-da985",
    storageBucket: "lunadeoro-da985.appspot.com",
    messagingSenderId: "795318821835",
    appId: "1:795318821835:web:0eac79ce8eb7b0d63a8c4e",
    measurementId: "G-P3CST0M79F"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
