import { useEffect, useState, createContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // null means logged out
  const [loading, setLoading] = useState(true); // Keep track of loading state

  useEffect(() => {
    // Set up an authentication state listener
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser); // Set the user when authenticated
      } else {
        setUser(null); // Set user to null when logged out
      }
      setLoading(false); // Loading is done
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {!loading && children} {/* Ensure children are only rendered once loading is complete */}
    </UserContext.Provider>
  );
};
